import { Link } from "react-router-dom";
import SEO from "./SEO";

export default function HeroSection() {

  return (
    <>
      <SEO
        title="Veromi Arsiradam Law"
        description="Offering legal services across Niagara Region and surrounding areas in Employment Law, Human Rights Law, Civil Litigation, and more. Request a consultation today."
        name="Veromi Arsiradam Law"
        type="website"
      />
      <section id="heroSection" >
        <div className="hero--section">
          <div className="hero--section--img animated-style">
            <img src="./img/TransparentLogoNewcrop.png" alt="Veromi Arsiradam Law Logo" />
          </div>
          <div className="hero--section--content--box">
            <div className="hero--section--content">
              <p className="hero--section-description animated-style" style={{ marginBottom: "-1%" }}>
                We provide legal services across the Niagara Region and surrounding areas in the following areas of law:</p>

              <Link style={{ marginBottom: "-2%" }} className="hero--section-links animated-style" to="/services">Employment Law</Link>

              <Link style={{ marginBottom: "-2%" }} className="hero--section-links animated-style" to="/services">Civil Litigation</Link>

              <Link style={{ marginBottom: "-2%" }} className="hero--section-links animated-style" to="/services">Administrative Law</Link>

              <Link style={{ marginBottom: "-2%" }} className="hero--section-links animated-style hero--section-links-small" to="/services"> Professional Regulation</Link>

              <Link style={{ marginBottom: "-2%" }} className="hero--section-links animated-style" to="/services">Human Rights Law</Link>

              <p style={{ marginBottom: "-1%", marginTop: '-0px' }} className="hero--section-description animated-style">Request a consultation to learn how we can assist you.</p>

              <Link
                offset={-70}
                duration={500}
                to="/contact"
                className="btn btn-primary animated-style"
              >
                Request a Consultation
              </Link>
            </div>

          </div>
        </div>
        <p className="hero--section-description--acknowledgment animated-style">The firm operates on the traditional land of the Hatiwendaronk, Haudenosaunee and Anishinaabe
          peoples, including the Mississaugas of the First Credit. This territory is covered by the Upper
          Canada Treaties and is within the land protected by the Dish With One Spoon Wampum
          agreement. This gathering place is home to many First Nations, Métis, and Inuit peoples. The
          firm is committed to local and international solidarity with Indigenous and colonized peoples,
          and to providing meaningful legal services that support, uphold and advance Indigenous rights.
        </p>
      </section>
    </>
  );
}
