import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {


  return (
    <section className="not-found--container">
      <div className="not-found--content">
        <h1>404 - Page Not Found</h1>
        <p><br></br>We couldn't find the page you were looking for. This is either because:</p>
        <ul>
          <li>There is an error in the URL entered into your web browser. Please check the URL and try again.</li>
          <li>The page you are looking for has been moved or deleted.</li>
        </ul>
        <p>You can return to our homepage by <Link to="/">clicking here</Link>.</p>
      </div>
    </section>
  );
}
