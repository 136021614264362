import React, { useState, useEffect} from "react";
import { NavLink } from "react-router-dom";





function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <nav className={`navbar ${isScrolled ? "solid-bg" : ""}`}>
      <div>
        <NavLink to="/"><img style={{height:"80px", width:"auto", marginRight:"20px", marginTop:"0px", marginBottom:"0px", marginLeft:"20px", backgroundColor:"none"}} src="./img/TransparentLogoNewNav.png" alt="Veromi Arsiradam Law logo" /></NavLink> {/*TransparentLogoNewNav.png Try and play around */}
      </div>
      {/* <a className={`nav__hamburger ${navActive ? "active" : ""}`} onClick={toggleNav} href="#">
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a> */}
      <button className={`nav__hamburger ${navActive ? "active" : ""}`} onClick={toggleNav}>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </button>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul className="navbar--links">
          <li>
            <NavLink onClick={closeMenu} to="/"  className="btn btn-primary"> Home </NavLink>
          </li>
          <li>
            <NavLink onClick={closeMenu} to="/about"  className="btn btn-primary"> About</NavLink>
          </li>
          <li>
            <NavLink onClick={closeMenu} to="/services"  className="btn btn-primary"> Services</NavLink>
          </li>
        </ul>
        <ul className="navbar--consult" style={{paddingTop:"0px"}}>
          <li>
            <NavLink onClick={closeMenu} to="/contact" className="btn btn-primary" >
              Request a Consultation
            </NavLink>
          </li>
        </ul>
      </div>

    </nav>
  );
}

export default Navbar;
