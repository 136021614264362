import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import SEO from './SEO';
import { Link } from "react-router-dom";

export default function ContactMe() {
  const form = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');  // 'success' or 'error'
  const [alertMessage, setAlertMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cczjfab', 'template_h2bf0wy', form.current, 'R-60-v1Vgb0BPrGvp')
      .then(
        () => {
          console.log('SUCCESS!');
          setAlertMessage('Email sent successfully!');
          setAlertType('success');
          setAlertOpen(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setAlertMessage(`Failed to send email: ${error.text}`);
          setAlertType('error');
          setAlertOpen(true);
        },
      );
    e.target.reset();
  };

  return (
    <>
      <SEO
        title="Request a Consultation"
        description="Fill out the form below to inquire about our services."
        name="Veromi Arsiradam Law"
        type="website"
      />

      <section id="Contact" className="contact--section fade-in2">
        <div>
          <h2 style={{ fontSize: "38px" }}>Request a Consultation</h2>
          <p className="contact--form--description text-lg">
            We offer a 45-minute initial consultation to all new prospective clients, at a flat rate
            of $155 + tax during regular business hours, or $200 + tax outside of regular business
            hours. We may charge a higher consultation fee if your matter is particularly complex or requires
            significant preparation. This initial consultation allows us to gather information about your
            matter, determine whether it falls into an area of law we practice, conduct a preliminary review
            of your key documents, perform preliminary legal research if needed, provide you with an initial
            assessment about how we can assist you, and discuss potential retainer arrangements. If you
            decide to retain us, we will prepare a retainer agreement for your review and signature, and we
            will get started on your matter.
            <br /><br />
            <b>To request a consultation,</b> fill out the form below. We try to respond to all inquiries within 24 hours.
          </p>
        </div>
        <form className="contact--form--container" ref={form} onSubmit={sendEmail}>
          <div className="container">
            <label htmlFor="first_name" className="contact--label">
              <span className="text-md"><b>First Name</b></span>
              <input
                type="text"
                className="contact--input text-md"
                name="first_name"
                id="first_name"
                required
              />
            </label>
            <label htmlFor="last_name" className="contact--label">
              <span className="text-md"><b>Last Name</b></span>
              <input
                type="text"
                className="contact--input text-md"
                name="last_name"
                id="last_name"
                required
              />
            </label>
            <label htmlFor="from_email" className="contact--label">
              <span className="text-md"><b>Email</b></span>
              <input
                type="email" // Corrected type from 'from_email' to 'email'
                className="contact--input text-md"
                name="from_email"
                id="from_email"
                required
              />
            </label>
            <label htmlFor="phone_number" className="contact--label">
              <span className="text-md"><b>Phone</b></span>
              <input
                type="number"
                className="contact--input text-md"
                name="phone_number"
                id="phone_number"
                required
              />
            </label>
          </div>
          <label htmlFor="subject" className="contact--label">
            <span className="text-md"><b>Subject (limit: 20 words)</b></span>
            <input
              id="subject"
              name="subject"
              className="contact--input text-md"
              type="text"
              required
            />
          </label>
          <label htmlFor="message" className="contact--label">
            <span className="text-md"><b>Brief Description (limit: 100 words)</b></span>
            <p className="contact--label--description" >Please provide a brief and general message describing your matter. Do not include confidential
              information or details. Please note that sending us a message does not create a lawyer-client
              relationship with the firm, nor does it create a reasonable expectation that the firm undertakes to
              provide legal advice or legal representation. You will not be considered a client unless we have
              agreed to act for you in accordance with the terms of a retainer agreement.</p> {/*left justify */}
            <textarea
              className="contact--input text-md"
              name="message"
              id="message"
              rows="8"
              required
            />
          </label>
          <span className="text-sm">You will receive a copy of this information when you click submit.</span>
          <label htmlFor="checkbox" className="checkbox--label">
            <input type="checkbox" required name="checkbox" id="checkbox" />
            <span className="text-sm">I accept the{' '}
              <Link
                offset={-70}
                duration={500}
                to="/privacy_policy"
                className="text-sm"
              >
                 Privacy Policy and Terms of Use
              </Link></span>
          </label>
          <div>
            <button type="submit" className="btn btn-primary contact--form--btn">Submit</button>
          </div>
        </form>
        <Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={alertType}  // 'success' or 'error'
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => { setAlertOpen(false); }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Box>
      </section>
    </>
  );
}
