import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import AboutMe from "./Pages/Home/AboutMe";
import ContactMe from "./Pages/Home/ContactMe";
import Footer from "./Pages/Home/Footer";
import HeroSection from "./Pages/Home/HeroSection";
import MySkills from "./Pages/Home/MySkills";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import NotFound from "./Pages/Home/NotFound";
import ScrollToTop from "./Pages/Home/ScrollToTop";

function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Navbar />
          <div>
            <Routes>
              <Route path="/" element={<HeroSection />}></Route>
              <Route path="/services" element={<MySkills />}></Route>
              <Route path="/about" element={<AboutMe />}></Route>
              <Route path="/contact" element={<ContactMe />}></Route>
              <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </div>

  );
}

export default App;
