import React from "react";
import SEO from "./SEO";

function PrivacyPolicy() {
  return (
    <>
      <SEO
        title="Privacy Policy and Terms of Use"
        description="Privacy Policy and Terms of Use for Veromi Arsiradam Law website."
        name="Veromi Arsiradam Law"
        type="website"
      />

      <section className="policy--container">
        <div className="policy--content">
          <h1>Privacy Policy and Terms of Use</h1>
          <p>Last updated: May 4, 2024</p>

          <h2>Privacy Rights and Obligations</h2>
          <p>Veromi Arsiradam Law (“We” or “the Firm”) respects Your privacy. All Canadian businesses engaged in commercial activities including law firms must comply with the Personal Information Protection and Electronic Documents Act (PIPEDA) or other substantially similar provincial legislation. PIPEDA gives Canadians rights concerning the privacy of personal information. The terms of this Privacy Policy accord with PIPEDA and other applicable privacy legislation.</p>
          <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit the Firm’s website including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively the "website"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site. By accessing or using our Site, you agree to this Privacy Policy.</p>
          <p>This Privacy Policy is current as of the “last updated” date listed above. We will from time to time review and revise this Privacy Policy and post the changes to Our website. Any changes become effective immediately upon publication on the Firm’s website, and You waive specific notice of any changes to this Policy by continuing to use and access the Firm’s website. You are deemed to have accepted any changes to any revised Privacy Policy by Your continued use of the Firm’s website after the revised Privacy Policy is posted.</p>

          <h2>Collection of Information</h2>
          <h3>Personal Information</h3>
          <p>Personal information is any information that identifies an individual or which allows an individual to be identified. Such information includes Your name, age, gender, mailing address, email address, and telephone number.</p>
          <p>We need to collect, use, and disclose personal information to provide legal services and information, to meet our professional obligations as lawyers, and to administer and manage the Firm’s business. We collect personal information directly from clients at the start of a retainer and in the course of Our representation. In the course of Our representation, we may also obtain information from other sources.</p>
          <p>You consent to giving Us this information by providing it to Us voluntarily on Our website. You provide some of this information when using the Firm’s contact form to request a consultation or contacting Us with questions. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but Your refusal may prevent You from accessing certain benefits from Our website or from being able to access our legal services.</p>

          <h3>Derivative Data</h3>
          <p>Our website, like most other commercial websites, employs software to monitor network traffic. This software receives and records the Internet Protocol (IP) address of the computer that has contacted our website, the date and time of the visit, and the pages visited.</p>
          <p>Derivative data is information that the Firm’s website servers automatically collect about You when You access Our website, such as Your Internet Protocol (IP) address, browser type, the dates and times that You access Our website, and the specific pages You view. Derivative data may also include data collected by third-party service providers such as analytics providers, and may include Cookies, log data, or web beacons. We discuss Cookies more fully below.</p>
          <p>If You use Our website with a mobile device or app, We may collect information about Your mobile device, location information, and other derivative data.</p>
          <p>This information is used to identify unauthorized attempts to upload or change information or otherwise cause damage and may be compiled into statistical information on traffic patterns and used to assess site efficiency for business purposes.</p>

          <h3>Social Media Data</h3>
          <p>We may access personal information about You from social media sites and apps such as LinkedIn, Twitter, or other social media sites not specifically named here. Such information may include Your name, social media username, location, age, gender, email address, and any other public information. If You do not want Us to access this information, please change Your privacy settings on those sites or apps.</p>

          <h2>Use of Personal Information</h2>
          <p>We use personal information to provide legal advice and services to You; to fulfill Our professional responsibilities to You; to administer billing; to develop and build our knowledge management and precedent systems; to deliver information about our services or developments in the law; to customize Our website and enhance Your experience with the site; and for any other purposes to which You or others expressly consent.</p>
          <p>We will not send You newsletters or informational emails unless You consent to us providing this information to You. Upon request to stop receiving information about our services or new developments in the law, We will not send any further material.</p>
          <p>We do not disclose personal information to any third parties to enable them to market their products and services to You.</p>
          <p>We may use the information and data described above to:</p>
          <ul>
            <li>open Your file and administer Your account;</li>
            <li>perform conflict checks;</li>
            <li>correspond with You;</li>
            <li>send You newsletters, informational emails, or updates about the Firm or Our website;</li>
            <li>request feedback from You;</li>
            <li>resolve disputes and troubleshoot any problems;</li>
            <li>compile aggregative or statistical data for business, marketing purposes;</li>
            <li>prevent fraudulent activity on the Firm’s website; and</li>
            <li>perform other functions related to business management and client management in order to provide legal services to You and other clients.</li>
          </ul>

          <h2>Disclosure of Personal Information</h2>
          <p>Under certain circumstances, such as the following, We will disclose Your personal information:</p>
          <ul>
            <li>when We are required or authorized by law to do so (e.g., to respond to a subpoena, etc.);</li>
            <li>when We have Your consent to make the disclosure;</li>
            <li>when the legal services We are providing require Us to give Your information to third parties, consent will be implied unless You tell us otherwise;</li>
            <li>where it is necessary to establish or collect legal fees;</li>
            <li>if We engage a third party to provide administrative services to us (e.g., computer and technology services, internet-based storage, or archival file storage);</li>
            <li>if We engage expert witnesses on Your or a client’s behalf;</li>
            <li>if We retain other law firms at Your or a client’s request and on Your or their behalf; or</li>
            <li>if it is necessary to do so in order to comply with any legal obligations or professional obligations in providing You with legal services and advice.</li>
          </ul>
          <p>We may also, under certain circumstances, disclose Your personal information for purposes related to the Firm’s legitimate business purposes, administration of the Firm’s website, administration of client files and accounts, and entering into contacts with You.</p>
          <p>The legal basis for the Firm’s disclosure of Your personal information is both Your Consent to Our Privacy Policy and the Firm’s right to protect and promote Our legitimate business interests.</p>

          <h2>Consent</h2>
          <p>Submitting personal information to the Firm grants us permission to collect, use, and disclose Your personal information in accordance with this Privacy Policy and as permitted or required by law. At times, We may ask for Your express consent, either verbally or in writing.</p>

          <h2>Accuracy and Access</h2>
          <p>Ensuring that the information We in our records are correct and current is crucial. We encourage clients to let the Firm know if any of their information changes during the course of their retainer so we can keep our records up-to-date.</p>
          <p>Following the guidelines of PIPEDA, We will grant access to personal information upon request unless the information is protected under solicitor-client privilege or other legal restrictions on disclosure.</p>
          <p>If the Firm has personal information about clients or others who can establish that it is not accurate, complete, or current, We will do what we reasonably can to correct the information.</p>

          <h2>Security of Personal Information</h2>
          <p>The firm takes all reasonable precautions to ensure that personal information is kept safe from loss, unauthorized access, modification, or disclosure. We have put in place suitable physical, electronic, and firm administrative procedures to safeguard and secure the information we collect online.</p>
          <p>Among the steps We take to protect personal information are:</p>
          <ul>
            <li>Data Privacy Standards: We require our email and internet-based service providers to meet or exceed international standards for data privacy, security, and information governance practices, including ISO27001 certification;</li>
            <li>Secure Website Infrastructure: We ensure that the Firm’s website is built on a secure infrastructure with the latest security protocols and encryption standards (e.g., HTTPS).</li>
            <li>Regular Updates and Patches: We keep all software, including web servers, CMS platforms, and plugins, updated with the latest security patches to address vulnerabilities.</li>
            <li>Strong Authentication: We implement strong authentication mechanisms, such as multi-factor authentication (MFA), to verify the identity of users accessing sensitive areas of the website.</li>
            <li>Firewall Protection: We install and configure a firewall to monitor and control incoming and outgoing network traffic, helping to prevent unauthorized access and malicious attacks.</li>
            <li>Regular Security Audits: We implement software to conduct regular security audits and vulnerability assessments to identify and address potential security weaknesses before they can be exploited by attackers.</li>
            <li>Security Training: We engage in training on cybersecurity best practices, including how to recognize and respond to phishing attempts and other social engineering attacks.</li>
            <li>Data Privacy Compliance: We ensure compliance with relevant data privacy regulations such as the Personal Information Protection and Electronic Documents Act (PIPEDA) by implementing appropriate data handling and storage practices.</li>
            <li>Incident Response Plan: We develop and maintain incident response plans outlining procedures for responding to and mitigating security incidents, including data breaches or cyber attacks.</li>
          </ul>

          <p>While no data transmission (including over the Internet or any website) can be guaranteed to be secure from intrusion, we implement commercially reasonable physical, technical, and procedural measures to help protect personal data from unauthorized access, use, disclosure, alteration, or destruction, in accordance with data protection law requirements.</p>

          <h2>Communicating with Us</h2>
          <p>Email is not a 100% secure medium. Sending of confidential, privileged, or private correspondence, documents, and other information related to a legal matter through the Internet (including via videoconference, email, and messaging services) in an unencrypted condition cannot guarantee security or protection from interception by third parties.</p>
          <p>We aim to strike a reasonable balance between security and convenience in our communications with clients and others, for example, by using unencrypted email for some communications, subject to client instructions.</p>

          <h2>Changes to this Privacy Policy</h2>
          <p>The firm will from time to time review and revise this Privacy Policy and post the changes to our website.</p>

          <h2>Contact</h2>
          <p>If you have any questions about this Privacy Policy, please feel free to reach out to Veromi Arsiradam at <a href="mailto:contact@veromiarsiradamlaw.com">contact@veromiarsiradamlaw.com</a>.</p>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
