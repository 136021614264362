import SEO from "./SEO";

export default function AboutMe() {
  return (
    <>
      <SEO
        title="About"
        description="Veromi Arsiradam. Veromi is the Principal Lawyer of the firm and has represented clients in a variety of legal matters."
        name="Veromi Arsiradam Law"
        type="website"
      />
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            {/* <p className="section--title" style={{marginBottom:"-20px", fontSize:"24px"}}>About Me</p> */}
            <h1 className="about--section--heading " style={{ marginBottom: "-20px" }}>Veromi Arsiradam, PhD, JD</h1>
            <h2 style={{fontSize: "28px"}}>Principal Lawyer  </h2>
            <p className="about--section-description fade-in2" >
              Veromi practices in the areas of employment law, civil litigation, human rights law, professional
              regulation, and administrative law.
            </p>
            <p className="about--section-description fade-in2">
              She has represented clients in arbitrations and mediations, and has acted in matters before the
              Ontario Labour Relations Board, Human Rights Tribunal of Ontario, Ontario Superior Court of
              Justice (including Small Claims Court and Divisional Court), and the Ontario Court of Appeal.
            </p>
            <p className="about--section-description fade-in2">
              Veromi obtained a Juris Doctor (JD) degree from Osgoode Hall Law School, where she was
              awarded the Bora Laskin Prize and the PBSC Chief Justice Richard Wagner Award for
              Outstanding Leadership. After law school, Veromi summered, articled, and worked as an
              associate at a leading boutique labour and employment firm in Toronto.
            </p>
            <p className="about--section-description fade-in2">
              Prior to commencing her legal career, Veromi obtained a BA(Hons) and PhD in Philosophy,
              specializing in ethics, and social and political philosophy. She received numerous scholarships
              and awards for her academic work in Philosophy, including a University Medal, SSHRC
              Doctoral Scholarship, and Doctoral Excellence Research Award.
            </p>
            <p className="about--section-description fade-in2">
              Veromi is a member of Canadian Lawyers for International Human Rights, The Advocates’
              Society, Toronto Lawyers Association, the Canadian/Ontario Bar Association, and the Federation
              of Asian Canadian Lawyers.
            </p>
          </div>
        </div>
        <div className="about--section--img animated-style">
          <img src="./img/veromi.png" alt="Veromi Arsiradam" />
        </div>
      </section>
    </>
  );
}
