import { Link } from "react-router-dom";
import React from "react";
import { IoIosTime } from "react-icons/io";
import { MdContactMail } from "react-icons/md";



function Footer() {
  return (
    <footer className="footer--container">
      <div className="footer--link--container">

        <div className="footer--items">
          <ul>
            <li>
              <div className="footer--section--card fade-in2" >
                <div className="footer--section--img">
                  <IoIosTime size={50} color="#1f699f" />
                </div>
                <div className="footer--section--card--content">
                  <h3 className="footer--section--title">Regular Business Hours</h3>
                  <p className="footer--section--description" >Monday - Friday <br></br> 9am-5pm <br></br> Outside Regular Business Hours as needed</p>

                </div>
              </div>
            </li>
            <li>
              <div className="footer--section--card fade-in2" >
                <div className="footer--section--img">
                  <MdContactMail size={50} color="#1f699f" />
                </div>
                <div className="footer--section--card--content">
                  <h3 className="footer--section--title">Contact Us</h3>
                  <p className="footer--section--description" >Email: contact@veromiarsiradamlaw.com <br></br>Phone: +1 (289) 212-7979</p>

                </div>
              </div>
            </li>
          </ul>
        </div>

      </div>
      <hr className="divider" />
      <div className="footer--content--container">
        <p className="footer--content text-sm">Made by Akhaylin Rajagopaul</p>
        <div className="footer--social--icon">
          <ul>
            <li>
              <Link
                offset={-70}
                duration={500}
                to="/privacy_policy"
                className="text-sm"
              >
                Privacy Policy and Terms of Use
              </Link>
            </li>

          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
