import React from 'react';
import { GiTiedScroll } from "react-icons/gi";
import { BiSolidBriefcaseAlt2 } from "react-icons/bi";
import { PiGavelDuotone } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { BsFeather } from "react-icons/bs";
import SEO from './SEO';

export default function MySkills() {
  const cardData = [
    {
      icon: <GiTiedScroll size={50} />,
      title: "Employment Law",
      description: "We offer services to employees and other workers with respect to:",
      list: [
        "employment contracts",
        "severance packages",
        "accommodations and leaves of absence",
        "human rights",
        "wrongful dismissal",
        "constructive dismissal",
        "and other workplace-related issues"
      ]
    },
    {
      icon: <BiSolidBriefcaseAlt2 size={50} />,
      title: "Professional Regulation",
      description: "We represent regulated professionals in matters involving their governing college, including with respect to:",
      list: [
        "licensing and privileging",
        "submissions to Registration, Complaints and Executive Committees",
        "representation before Fitness to Practice, Registration, and Discipline Committees"
      ],
      additionalDescription: "",
      additionalList: []
    },
    {
      icon: <PiGavelDuotone size={50} />,
      title: "Civil Litigation",
      description: "We represent parties and interveners in civil matters at trial and appellate levels of court. We are particularly interested in matters involving issues of public interest, including those that engage domestic and international human rights law.",
      list: [],
      additionalDescription: "",
      additionalList: []
    },
    {
      icon: <FaPeopleGroup size={50} />,
      title: "Human Rights Law",
      description: "We provide advice and representation to those alleging discrimination on the basis of race, sex, gender, disability, age, and other protected grounds under Ontario’s Human Rights Code and the federal Canadian Human Rights Act.",
      list: [],
      additionalDescription: "",
      additionalList: []
    },
    {
      icon: <BsFeather size={50} />,
      title: "Administrative Law",
      description: "We represent clients before administrative tribunals, including the Human Rights Tribunal of Ontario, and in judicial reviews and appeal proceedings.",
      list: [],
      additionalDescription: "",
      additionalList: []
    }
  ];

  return (
    <>
      <SEO
        title="Services"
        description="Explore our Ontario-based legal services in Employment Law, Professional Regulation, Civil Litigation, Human Rights Law, and Administrative Law."
        name="Veromi Arsiradam Law"
        type="website"
      />
      <section className="skills--section" id="mySkills">
        <div className="portfolio--container">
          <h2 className="skills--section--heading">Services</h2>
        </div>
        <div className="skills--section--container">
          {cardData.map((card, index) => (
            <div className="skills--section--card fade-in2" style={{ animationDelay: `${index * 0.3}s` }} key={index}>
              <div className="skills--section--img">
                {card.icon}
              </div>
              <div className="skills--section--card--content">
                <h3 className="skills--section--title">{card.title}</h3>
                <p className="skills--section--description" dangerouslySetInnerHTML={{ __html: card.description }}></p>
                <ul style={{ marginTop: "-17px" }} className="skills--section--description">
                  {card.list.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
                {card.additionalDescription && (
                  <>
                    <p style={{ marginTop: "-35px" }} className="skills--section--description" dangerouslySetInnerHTML={{ __html: card.additionalDescription }}></p>
                    <ul style={{ marginTop: "-17px" }} className="skills--section--description">
                      {card.additionalList.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
